import { PostTemplateProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx21WeekEmaChartTemplate from '@/templates/crypto/xxx-21-week-ema-chart';

export default function Solana21WeekEmaChart({ location }: PostTemplateProps) {
  return (
    <Xxx21WeekEmaChartTemplate
      coinAbbreviation="SOL"
      coinFullName="Solana"
      token={Token.SOLUSDT}
      location={location}
      articleId={ArticleList.SOLANA_21_WEEK_EMA_CHART}
    ></Xxx21WeekEmaChartTemplate>
  );
}
